<template>
  <div>
    <base-header base-title="Pasien"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <!-- <base-title :head-title="headTitle"></base-title> -->
              <div class="kt-portlet__head-toolbar">
                <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-bold" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active show" data-toggle="tab" href="#patient" role="tab" aria-selected="true">
                           Cari Berdasarkan Pasien
                        </a>
                    </li>
                    <li class="nav-item" disabled>
                        <a  class="nav-link">
                            Cari Berdasarkan Login Pasien
                        </a>
                    </li>
                </ul>
            </div>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <auto-refresh
                ref="auto"
                :table-name="tableData.tableName"
                :on-refresh="fetch"
                :auto-refresh-state="false"
              ></auto-refresh>
              <template v-if="checkPermission('PatientResource.POST.Patient')">
                &nbsp;&nbsp;
                <base-button-modal
                  :button-text="modalText"
                  :button-icon="modalIcon"
                  :button-name="modalName"
                  :onButtonModalClicked="showModal"
                ></base-button-modal>
              </template>
            </div>
          </div>
        </div>
        <div
          class="kt-portlet__body tab-content"
          v-if="isLoaded"
          id="patient_tab"
        >
        <div class="tab-pane fade active show" id="patient" role="tabpanel">
          <patient-server-table ref="patients" :patient-vue="patientVue"/>
        </div>
        <div class="tab-pane fade" id="user-patient" role="tabpanel">
          <!-- <user-patient-table/> -->
        </div>
        </div>
        <div
          v-else
          class="m-auto p-5 d-flex flex-row h-100"
        >
          <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
        <form-modal
          v-if="isShowModal"
          @event="fetch"
        ></form-modal>
        <form-modal-location
          v-if="isShowModalLocation"
          :show-modal.sync="isShowModalLocation"
          @event="fetch"
          :modal-name="modalLocationName"
          :table-name="tableData.tableName"
          :modal-data="dataEditLocation"
        ></form-modal-location>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "./../_general/AutoRefresh";
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import BaseButtonModal from "./../_base/BaseButtonModal";
import DataTables from "./../_table/DataTables";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const MasterRepository = RepositoryFactory.get("patient");
import { mapState, mapMutations, mapActions } from 'vuex';
import PatientServerTable from './_components/PatientServerTable';
// import UserPatientTable from './_components/UserPatientTable';

export default {
  components: {
    AutoRefresh,
    DataTables,
    BaseTitle,
    BaseHeader,
    BaseButtonModal,
    PatientServerTable,
    // UserPatientTable,
    FormModal: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-patient" */ "./_components/FormModal.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormModalLocation: () => ({
      component: new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            import(
              /* webpackChunkName: "modal-location-medical-record" */ "../medical_records/_components/FormModalLocation.vue"
            )
          );
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      patientVue: vx,
      isLoaded: true,
      isLoading: false,
      isFirstReload: true,
      modeSearch: true,
      dataEditLocation: null,
      isShowModalLocation: false,
      tableHeaderClass: 'blocked',
      tableBodyClass: 'vuetable-semantic-no-top blocked',
      modalLocationName: "modal_mr_location",
      headTitle: "Daftar Pasien",
      modalText: "Tambah Pasien",
      modalIcon: "flaticon2-add-1",
      tableData: [],
    };
  },
  computed: {
    ...mapState('MasterStore', {
      repository: state => state.repository,
      // isLoaded: state => state.isLoaded,
      table: state => state.table,
      isShowModal: state => state.isShowModal,
      dataEdit: state => state.dataEdit,
      modalName: state => state.modalName
    }),
  },
  watch: {
    isShowModal: function (val) {
      if (!val) {
        this.setEditData(null);
      }
    },
    isShowModalLocation: function (val) {
      if (!val) {
        this.dataEditLocation = null;
      }
    }
  },
  methods: {
    ...mapMutations({
      setupTable: 'MasterStore/SETUP_TABLE',
      showModal: 'MasterStore/SHOW_MODAL',
      setRepository: 'MasterStore/SET_REPOSITORY',
      setModal: 'MasterStore/SET_MODAL',
      setEditData: 'MasterStore/SET_EDIT_DATA',
      // setLoaded: 'MasterStore/SET_LOADED'
    }),
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    onLoaded: function(t) {
      t == 0 ? this.isLoading = false : this.isLoading = true  
    },
    fetch: function(resolve) {
      this.$refs.patients.$refs.table.getData();
      if(resolve != undefined){
          resolve();
      }
    },
    showToastError: function(message) {
        toastr.error(message);
    }
  },
  created() {
    // this.setLoaded(false);
    this.setRepository(MasterRepository);
    this.setModal('modal_patient');
  },
};
</script>