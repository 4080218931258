<template>
  <v-server-table
        :columns="columns"
        :options="options"
        name="master_patient"
        ref="table"
        @loaded="onLoaded(0)"
        @loading="onLoaded(1)"
    >
        <div slot="action" slot-scope="{ row }">
            <btn-action :row-data="row"
            :patient-vue="patientVue" :storeObject="$store"></btn-action>
        </div>
        <div v-if="isLoading" slot="afterBody" >
            <h6 class="txt"> loading..</h6>
        </div>
        <div slot="filter__gender">
                <select @change="filterInitial">
                    <option value="">All</option>
                    <option value="MALE">LAKI-LAKI</option>
                    <option value="FEMALE">PEREMPUAN</option>
                </select>
        </div>
    </v-server-table>
</template>

<script>
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PatientRepository = RepositoryFactory.get("patient");
import BtnAction from "./../_subcomponents/BtnAction";
import { Event } from "vue-tables-2";

export default {
    name: 'patient-server-table',
    components: {
        BtnAction
    },
    props: {
        patientVue: {
            type: Object
        }
    },
    data () {
        var vx = this
        return {
            tableHeaderClass: 'blocked',
            tableBodyClass: 'vuetable-semantic-no-top blocked',
            isLoading: false,
            columns: ['id', 'patientName','dateOfBirth','mobilePhone1','email', 'gender', 'agama.name', 'golDarah', 'etnis.name', 'pendidikan', 'ssn','medicalNo', 'action'],
            options: {
                texts: {
                    filterBy: 'Cari',
                },
                filterable: ['patientName','mobilePhone1','email','agama.name', 'golDarah', 'etnis.name', 'pendidikan', 'ssn','medicalNo', 'medicalNo'],
                headings: {
                    id: 'ID',
                    patientName: "Nama",
                    dateOfBirth: "Tanggal Lahir",
                    mobilePhone1: "No HP",
                    email: "Email",
                    "gender": "Jenis Kelamin",
                    "agama.name": 'Agama',
                    golDarah: 'Golongan Darah',
                    "etnis.name": 'Etnis',
                    pendidikan: 'Pendidikan',
                    ssn: 'SSN',
                    medicalNo: "No Rekam Medik",
                    action: 'Actions'
                },
                debounce: 800,
                customFilters: ["gender"],
                toMomentFormat: 'YYYY-MM-DD',
                requestFunction(data) {
                    if (this.abortController) {
                        this.abortController.abort()
                    }
                    const sort = 'id,asc'
                    this.isLoaded = false
                    const methodStr = `searchMasterPasien`
                    const params = helpers.filterTablePatient(data, sort);
            
                    const repo = PatientRepository[methodStr](params)
                    this.abortController = repo.next().value
                    const reposi = repo.next().value

                    repo.next()
                    return reposi.catch(e => {
                        console.log(e , "Request Function Patient Server Table");
                    })
                },
                responseAdapter: (resp) => {  
                    return {
                        data: helpers.mapperMasterPasien(resp.data),
                        count: resp.headers['x-count'] ? resp.headers['x-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
                    }
                },
                rowClassCallback(row) {
                    return  (row.isNewData) ? 'highlight':'';
                },
                filterByColumn: true,
                sortable:[],
                templates: {
                    gender(h, row) {
                        return vx.$t(row.gender)
                    }
                },
                stickyHeader: true
            },
            tableData: [],
            abortController: null
        }
    },
    methods: {
        onLoaded: function(t) {
            t == 0 ? this.isLoading = false : this.isLoading = true  
        },
        filterInitial(el) {
            Event.$emit("vue-tables.master_patient.filter::gender", el.target.value);
        }
    }

}
</script>